import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { ApiResponse, DefaultGuestClaims } from '../../../types';
import { useDefaultUserStore } from '../../../store';
import { API_IDENTITY } from '../../../api/api.base';
import { Alerter } from '../../../utils';
import { LinkItems, QueryKeys } from '../../../constants';
import { OnboardingForm } from '../../../pages/Messenger/validation';

export const useCustomCampaignMutation = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { user } = useDefaultUserStore();

	return useMutation<ApiResponse<string>, unknown, OnboardingForm>({
		mutationFn: payload =>
			API_IDENTITY.post(
				'/api/user/active-campaign/custom-fields',
				payload,
			),
		onSuccess(data) {
			if (data.success) {
				queryClient.invalidateQueries({
					queryKey: [QueryKeys.CHECK_TOS_STATUS],
				});
				if (user) {
					navigate(
						LinkItems.find(
							elem =>
								elem.id ===
								Array.from(
									user?.claimsIds || DefaultGuestClaims,
								).sort()[0],
						)!.link,
					);
				}
			} else {
				Alerter.error(data?.errors?.[0].message || 'Error');
			}
		},
		onError(error) {
			Alerter.error(error as any);
		},
	});
};
