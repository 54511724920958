/* eslint-disable indent */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useChannel } from 'ably/react';
import { useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { Message } from 'ably';
import { Loader } from '../../../../components';
import { DateDivider, MessageWithAddons } from '../../messages-components';
import {
	DeleteMessageConfirmModal,
	PinMessageConfirmModal,
} from '../../modals';
import {
	ChatType,
	EditMessageDisplayData,
	JumpToPresentConditionEnum,
	MessageReactionType,
	NewChannelMessageEventData,
	ReplyMessage,
	ServerChannelData,
} from '../../types';
import { StartOfServerChat } from './StartOfServerChat';
import {
	useChatUserStore,
	useMessageNavigationStore,
	useSuspendModalStore,
} from '../../../../store';
import { extractMentionsIds } from '../../utils';
import {
	useCreateChannelMessageReaction,
	useDeleteChannelMessageReaction,
	useDeleteUserChannelMessageByAdmin,
	useFindChannelMessage,
	useGetChannelMessages,
	usePinMessage,
	useUpdateChannelMessageReaction,
	useUpdateChannelsBanStatus,
} from '../../queries';
import { deleteChannelMessage, editChannelMessage } from '../../helpers';
import { DEFAULT_LAST_READ_TIME, SOCKET_EVENTS } from '../../constants';
import { useUpdateReactionsList } from '../../hooks';
import { MessengerQueryKeys } from '../../queries/query-keys';
import { useHandleServerMessagesSocketEvents } from '../hooks';
import { Alerter, filterAblyErrorsToSentry, wait } from '../../../../utils';

interface ServerMessagesListProps {
	id?: string;
	onSetReplyMessage: (obj: ReplyMessage) => void;
	replyMessage: ReplyMessage | null;
	channelData?: ServerChannelData | null;
	lastReadTime?: string;
	disableMenu?: boolean;
	updateLastReadTime: (time: string) => void;
	updateUnreadCount: Dispatch<SetStateAction<number>>;
	onMarkAsRead: (id: number) => Promise<void>;
	setFindLoading: (value: boolean) => void;
}

export const ServerMessagesList: React.FC<ServerMessagesListProps> = ({
	id,
	onSetReplyMessage,
	channelData,
	lastReadTime,
	replyMessage,
	disableMenu,
	updateLastReadTime,
	updateUnreadCount,
	onMarkAsRead,
	setFindLoading,
}) => {
	const [messageToDelete, setMessageToDelete] =
		useState<EditMessageDisplayData | null>(null);
	const [messageToPin, setMessageToPin] =
		useState<EditMessageDisplayData | null>(null);
	const [highlightedId, setHighlightedId] = useState<number | null>(null);
	const [transitionLoading, setTransitionLoading] = useState(false);

	const { onOpen } = useSuspendModalStore();
	const { user } = useChatUserStore();
	const {
		jumpToPresentCondition,
		setJumpToPresentCondition,
		messageAnchorId,
		setMessageAnchorId,
	} = useMessageNavigationStore();
	const { mutate } = useUpdateChannelsBanStatus();
	const { pinMessage } = usePinMessage();
	const { mutateAsync: createChannelMessageReaction } =
		useCreateChannelMessageReaction();
	const { mutateAsync: updateChannelMessageReaction } =
		useUpdateChannelMessageReaction();
	const { mutateAsync: removeChannelMessageReaction } =
		useDeleteChannelMessageReaction();
	const { mutateAsync: deleteMessageByAdmin } =
		useDeleteUserChannelMessageByAdmin();
	const { mutate: updateMessageReactionsList } = useUpdateReactionsList(
		MessengerQueryKeys.GET_CHANNEL_MESSAGE_REACTIONS_PREVIEW,
	);

	const queryClient = useQueryClient();

	const shouldCallMarkAsReadRef = useRef(true);

	const bottomInView = useInView(); // scroll down
	const topInView = useInView(); // scroll up
	const parentRef = useRef<HTMLDivElement>(null);
	const currentFetchDirectionRef = useRef('next');
	const isJumpingFromDeepRef = useRef(false);

	const {
		newMessages,
		handleServerMessageSocketEvent,
		setHistoryMessages,
		historyMessages,
		setNewMessages,
	} = useHandleServerMessagesSocketEvents({
		id,
		updateUnreadCount,
		updateLastReadTime,
		parentRef,
		shouldCallMarkAsReadRef,
	});

	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		isFetching,
		hasPreviousPage,
		isFetchingPreviousPage,
		fetchPreviousPage,
	} = useGetChannelMessages(id ? +id : null);

	const resetMessagesStateOnTransition = (id: number) => {
		queryClient.resetQueries({
			queryKey: [MessengerQueryKeys.GET_CHANNEL_MESSAGES, id],
		});
		setTransitionLoading(true);
		currentFetchDirectionRef.current = 'next';
		isJumpingFromDeepRef.current = true;
		setNewMessages([]);
		setHistoryMessages([]);
		return;
	};

	const handleNewMessageEventFromDeep = (message: Message) => {
		const newMessageData: {
			Value: NewChannelMessageEventData;
		} = JSON.parse(message.data);

		const { SenderId, ChannelId } = newMessageData.Value;
		if (!id || Number(id) !== ChannelId || SenderId !== user?.userId) {
			return;
		}
		resetMessagesStateOnTransition(+id);
	};

	useChannel(
		{
			channelName: `private-channel:${id}`,
			onChannelError: err => {
				filterAblyErrorsToSentry(err);
			},
			onConnectionError: err => {
				console.log(err, 'private channel connection err');
				filterAblyErrorsToSentry(err);
			},
		},
		message => {
			const isNewChannelMessageEvent =
				message.name === SOCKET_EVENTS.CHANNEL_MESSAGE_EVENT;

			if (isNewChannelMessageEvent && hasPreviousPage) {
				handleNewMessageEventFromDeep(message);
				return;
			}

			handleServerMessageSocketEvent(message);
		},
	);

	const handleViewPositionOnScrollDown = (
		isFetchingPreviousPage: boolean,
	) => {
		const parentContainer = parentRef.current;

		if (
			isFetchingPreviousPage ||
			!parentContainer ||
			currentFetchDirectionRef.current !== 'prev'
		) {
			return;
		}

		parentRef.current.scrollTo({
			top: -1,
			behavior: 'smooth',
		});
	};

	const combinedMessages = useMemo(
		() => [...newMessages, ...historyMessages],
		[newMessages, historyMessages],
	);

	useEffect(() => {
		if (bottomInView.inView && !isFetchingPreviousPage && hasPreviousPage) {
			currentFetchDirectionRef.current = 'prev';
			fetchPreviousPage();
		}
	}, [bottomInView.inView]);

	useEffect(() => {
		if (topInView.inView && !isFetchingNextPage && hasNextPage) {
			currentFetchDirectionRef.current = 'next';
			fetchNextPage();
		}
	}, [topInView.inView]);

	useEffect(() => {
		if (id && data?.pages.length && !isFetching) {
			const flatMessages =
				data?.pages?.flatMap(page => page?.messages || []) || [];
			setHistoryMessages(flatMessages);
			handleViewPositionOnScrollDown(isFetchingPreviousPage);
			updateLastReadTime(
				data?.pages?.[data?.pages.length - 1].userLastReadDateTime ||
					DEFAULT_LAST_READ_TIME,
			);
			updateUnreadCount(
				data?.pages?.[data?.pages.length - 1].unreadCount || 0,
			);

			if (parentRef.current && isJumpingFromDeepRef.current) {
				setJumpToPresentCondition(JumpToPresentConditionEnum.Hidden);
				setTransitionLoading(false);
				parentRef.current.scrollTo({
					top: -1,
					behavior: 'smooth',
				});
				isJumpingFromDeepRef.current = false;
			}
		}
	}, [data, id, isFetching]);

	useEffect(() => {
		return () => {
			if (user?.userId && id && shouldCallMarkAsReadRef.current) {
				onMarkAsRead(+id);
			}
		};
	}, [id, user]);

	const onEditMessage = async (messageId: number, newText: string) => {
		try {
			if (!channelData) {
				return;
			}
			const mentionsIds = extractMentionsIds(newText);

			await editChannelMessage({
				text: newText,
				messageId,
				mentionId: mentionsIds || [],
				channelId: channelData.id,
			});
		} catch (error) {
			console.error('Error editing message: ', error);
		}
	};

	const onDeleteMessage = async (messageId: number, byAdmin?: boolean) => {
		try {
			if (!channelData) {
				return;
			}
			if (byAdmin) {
				await deleteMessageByAdmin(messageId);
				return;
			}
			await deleteChannelMessage(messageId);
		} catch (error) {
			console.error('Error deleting message: ', error);
		}
	};

	const onPinMessage = async (messageId: number) => {
		try {
			if (!channelData || !id) {
				return;
			}

			const res = await pinMessage(messageId, +id);
			if (!res.success) {
				return;
			}
			await onMarkAsRead(+id);
		} catch (error) {
			console.error('Error pinning message: ', error);
		}
	};

	const onSuspendUser = (userId: number) => {
		onOpen(userId, false);
	};

	const unSuspendUser = (userId: number) => {
		mutate({ userId, isBanned: false, deleteMessage: false });
	};

	const onSuspendAndPurgeMessagesUser = (userId: number) => {
		onOpen(userId, true);
	};

	const onReact = async (
		messageId: number,
		emojiId: MessageReactionType,
		reactedEmojiId?: number | null,
	) => {
		try {
			if (!reactedEmojiId) {
				const res = await createChannelMessageReaction({
					channelMessageId: messageId,
					reactionEmojiId: emojiId,
				});
				if (!res.success) {
					return;
				}
				updateMessageReactionsList(emojiId, messageId, 'add');
				return;
			}
			if (emojiId === reactedEmojiId) {
				const res = await removeChannelMessageReaction(messageId);
				if (!res.success) {
					return;
				}
				updateMessageReactionsList(emojiId, messageId, 'remove');
				return;
			}
			await updateChannelMessageReaction({
				channelMessageId: messageId,
				reactionEmojiId: emojiId,
			});
		} catch (error) {
			console.log('onReact error: ', error);
		}
	};

	const [searchParams, setSearchParams] = useSearchParams();

	const targetMessageId = searchParams.get('targetMessageId');

	const {
		data: findData,
		// , isLoading: findLoading
	} = useFindChannelMessage(targetMessageId, +(id || 0));

	useEffect(() => {
		if (findData?.value?.messages?.length) {
			const targetMessage = findData?.value?.messages.find(el => {
				const searchedMessageId = targetMessageId
					? +targetMessageId
					: 0;

				return el.id === searchedMessageId;
			});
			if (!targetMessage) {
				setFindLoading(false);
				setSearchParams({});
				Alerter.error('Message not found');
				return;
			}
			queryClient.setQueryData(
				[MessengerQueryKeys.GET_CHANNEL_MESSAGES, +(id || 0)],
				oldData => {
					const reversedMessages = [
						...findData.value.messages,
					].reverse();

					currentFetchDirectionRef.current = 'center';
					return {
						pages: [
							{
								...findData.value,
								messages: reversedMessages,
								lastMessageId: reversedMessages.at(-1)?.id,
								firstMessageId: reversedMessages.at(0)?.id,
								direction: 'center',
							},
						],

						pageParams: [
							{
								direction: 'center',
								value: targetMessageId
									? +targetMessageId
									: null,
							},
						],
					};
				},
			);
			setNewMessages([]);
		}
	}, [findData, queryClient]);

	const onNavigateToMessage = async (messageId: number) => {
		const possibleMessageIndex = combinedMessages.findIndex(
			elem => elem.id === messageId,
		);

		if (possibleMessageIndex > -1) {
			const container = parentRef.current;
			const item = container?.children[possibleMessageIndex + 1]; //* +1 to index based on ref on the edges of the list
			if (item) {
				const itemYPosition = item.getBoundingClientRect().y;
				item.scrollIntoView({
					behavior:
						itemYPosition >= -1000 && itemYPosition <= 1000
							? 'smooth'
							: 'auto',
					block: 'center',
				});
				await wait(200);
				setHighlightedId(messageId);
				await wait(1000);
				setHighlightedId(null);
			}
			return;
		}

		const newSearchParams = new URLSearchParams(searchParams);
		newSearchParams.set('targetMessageId', messageId.toString());
		setSearchParams(newSearchParams);
		setFindLoading(true);
		setJumpToPresentCondition(JumpToPresentConditionEnum.ReplyMessageJump);
	};

	const onNavigateToLastChannelMessage = useCallback(async () => {
		if (!parentRef.current) {
			return;
		}

		setJumpToPresentCondition(JumpToPresentConditionEnum.Loading);
		if (!hasPreviousPage) {
			parentRef.current.scrollTo({
				top: -500,
			});
			parentRef.current.scrollTo({
				top: -1,
				behavior: 'smooth',
			});
			setJumpToPresentCondition(JumpToPresentConditionEnum.Hidden);
			return;
		}
		if (!id) {
			return;
		}
		resetMessagesStateOnTransition(+id);
	}, [hasPreviousPage]);

	const loadAllAttachments = async () => {
		const imageElements = Array.from(
			document.querySelectorAll('.attachment-image'),
		);

		const imageLoadPromises = imageElements.map(img => {
			// @ts-ignore
			if (img.complete) {
				return Promise.resolve();
			}
			return new Promise(resolve => {
				// @ts-ignore
				img.onload = img.onerror = resolve;
			});
		});

		await Promise.all(imageLoadPromises);
	};

	const onScrollContainer = useCallback(() => {
		const currentScrollPosition = parentRef.current?.scrollTop || 0;
		const { Hidden, Visible, Loading } = JumpToPresentConditionEnum;

		const isInCenterFetch = currentFetchDirectionRef.current === 'center';
		const isScrolledNearTop =
			currentScrollPosition >= -1500 && currentScrollPosition < 0;
		const isVisibleCondition =
			jumpToPresentCondition === Visible ||
			jumpToPresentCondition === Loading;
		const isReplying = !!replyMessage;

		if (
			isInCenterFetch &&
			!isReplying &&
			jumpToPresentCondition === Hidden
		) {
			setJumpToPresentCondition(Visible);
			return;
		}

		if (
			isScrolledNearTop &&
			!hasPreviousPage &&
			jumpToPresentCondition !== Hidden
		) {
			setJumpToPresentCondition(Hidden);
			return;
		}

		if (
			isInCenterFetch ||
			currentScrollPosition > -1500 ||
			isVisibleCondition ||
			isReplying
		) {
			return;
		}

		setJumpToPresentCondition(Visible);
	}, [jumpToPresentCondition, replyMessage, hasPreviousPage]);

	const highlightMessage = async (messageId: number) => {
		const index = combinedMessages.findIndex(msg => msg.id === messageId);

		if (index !== -1) {
			const container = parentRef.current;
			const item = container?.children[index + 1]; //* +1 to index based on ref on the edges of the list

			if (item) {
				await loadAllAttachments();
				setFindLoading(false);
				item.scrollIntoView({ block: 'center' });
			}
			setSearchParams({});
			await wait(200);
			setHighlightedId(messageId);
			if (!replyMessage) {
				setJumpToPresentCondition(JumpToPresentConditionEnum.Visible);
			}
			await wait(1000);
			setHighlightedId(null);
		}
	};

	useEffect(() => {
		if (targetMessageId) {
			highlightMessage(+targetMessageId!);
		}
	}, [targetMessageId, combinedMessages]);

	useEffect(() => {
		if (
			jumpToPresentCondition ===
			JumpToPresentConditionEnum.StartConversationJump
		) {
			onNavigateToLastChannelMessage();
		}
	}, [jumpToPresentCondition]);

	useEffect(() => {
		if (messageAnchorId) {
			onNavigateToMessage(messageAnchorId);
			setMessageAnchorId(0);
		}
	}, [messageAnchorId]);

	const setRefs = useCallback(
		(node: HTMLDivElement) => {
			// Callback refs, like the one from `useInView`, is a function that takes the node as an argument
			bottomInView.ref(node);
		},
		[bottomInView],
	);

	const getContainerHeight = () => {
		const offset =
			jumpToPresentCondition === JumpToPresentConditionEnum.Visible ||
			jumpToPresentCondition === JumpToPresentConditionEnum.Loading ||
			replyMessage
				? 230
				: 160;

		return `calc(100vh - ${offset}px)`;
	};

	const isLoadingMessages = useMemo(
		() =>
			transitionLoading ||
			(isFetching && !isFetchingNextPage && !isFetchingPreviousPage) ||
			(data?.pages?.[0]?.messages?.length !== 0 &&
				historyMessages.length === 0),
		[
			transitionLoading,
			isFetching,
			isFetchingNextPage,
			isFetchingPreviousPage,
			historyMessages.length,
			data?.pages?.[0]?.messages?.length,
		],
	);

	return (
		<>
			<Flex
				ref={parentRef}
				flexDirection="column-reverse"
				position="relative"
				h={getContainerHeight()}
				transition="height .2s ease"
				overflowY="auto"
				onScroll={onScrollContainer}>
				{isLoadingMessages ? (
					<Loader centerHeight="100%" />
				) : (
					<>
						<Box
							// ref={bottomInView.ref}
							ref={setRefs}
						/>
						{isFetchingPreviousPage ? (
							<Loader centerHeight="80px" spinnerSize="sm" />
						) : null}
						{combinedMessages.map((elem, index) => (
							<div
								key={elem.id}
								className={
									elem?.id === highlightedId
										? 'highlight'
										: ''
								}>
								<MessageWithAddons
									messageType={elem.type}
									arr={combinedMessages}
									data={elem}
									index={index}
									onEditMessage={onEditMessage}
									onSetDeleteMessage={setMessageToDelete}
									onSetReplyMessage={onSetReplyMessage}
									onSetPinMessage={setMessageToPin}
									allowFullAccess={
										user?.userId === elem.senderId ||
										user?.isAdmin
									}
									includeEdit={user?.userId === elem.senderId}
									lastReadTime={
										lastReadTime === DEFAULT_LAST_READ_TIME
											? undefined
											: lastReadTime
									}
									includePin={user?.isAdmin}
									hideNewDividerForNewUser={true}
									enableMentions={true}
									disableMenu={disableMenu}
									onSuspendUser={onSuspendUser}
									onSuspendAndPurgeMessagesUser={
										onSuspendAndPurgeMessagesUser
									}
									unSuspendUser={unSuspendUser}
									chatType={ChatType.CHANNEL}
									onReact={onReact}
									includeUserInfoPopup={true}
									onReplyPress={onNavigateToMessage}
									isReplyMessageJumpEnabled={true}
									isChatListFlow={true}
								/>
							</div>
						))}
						{combinedMessages?.length ? (
							<DateDivider
								date={
									new Date(
										combinedMessages[
											combinedMessages.length - 1
										].sentAt,
									)
								}
							/>
						) : null}
						{isFetchingNextPage ? (
							<Loader centerHeight="80px" spinnerSize="sm" />
						) : null}
						<Box ref={topInView.ref} />
						<StartOfServerChat channelData={channelData} />
					</>
				)}
			</Flex>
			<DeleteMessageConfirmModal
				isOpen={!!messageToDelete}
				onClose={() => setMessageToDelete(null)}
				messageToDelete={messageToDelete}
				onDeleteMessage={onDeleteMessage}
				chatType={ChatType.CHANNEL}
				currentUserId={user?.userId}
			/>
			<PinMessageConfirmModal
				isOpen={!!messageToPin}
				onClose={() => setMessageToPin(null)}
				message={messageToPin}
				action={onPinMessage}
				variant="add"
				chatType={ChatType.CHANNEL}
			/>
		</>
	);
};
