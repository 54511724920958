import { ComponentType } from 'react';
import Content, { ContentProps } from './Content';

export interface PopoverComponents {
	Content: ComponentType<ContentProps>;
}

export type PopoverComponentsType = Partial<PopoverComponents>;

const components = {
	Content,
};

type PopoverComponentGeneric = typeof components;

export const defaultComponents = (
	comps: PopoverComponentsType,
): PopoverComponentGeneric =>
	({
		...components,
		...comps,
	} as PopoverComponentGeneric);
