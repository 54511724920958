import './messaging_init.ts';
import React, { useEffect } from 'react';
import { OidcAuth, OnboardingTourProvider } from './components';
import { AppRoutes } from './App.routes';
import { setLightTheme, theme } from './theme';
import { ChakraProvider } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { APIProvider } from '@vis.gl/react-google-maps';
import { PUBLIC_ROUTES_PATHS, PublicRoutes } from './PublicRoutes';

const App: React.FC = () => {
	const loc = useLocation();
	useEffect(() => {
		setLightTheme();
		window.addEventListener('vite:preloadError', event => {
			window.location.reload();
		});
	}, []);

	if (
		loc.pathname === PUBLIC_ROUTES_PATHS.PP ||
		loc.pathname === PUBLIC_ROUTES_PATHS.TOS ||
		loc.pathname === PUBLIC_ROUTES_PATHS.STR
	) {
		return (
			<ChakraProvider theme={theme}>
				<PublicRoutes />
			</ChakraProvider>
		);
	}
	return (
		<ChakraProvider theme={theme}>
			<APIProvider apiKey={import.meta.env.VITE_GOOGLE_API_KEY}>
				<OidcAuth>
					<OnboardingTourProvider>
						<AppRoutes />
					</OnboardingTourProvider>
				</OidcAuth>
			</APIProvider>
		</ChakraProvider>
	);
};

export default App;
