import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API_IDENTITY } from '../api/api.base';
import { ApiResponseEmpty } from '../types';
import { QueryKeys } from '../constants';

export const useCompleteIntroductionGuide = () => {
	const queryClient = useQueryClient();
	return useMutation<ApiResponseEmpty>({
		mutationFn: () =>
			API_IDENTITY.patch('api/app/user/complete-introduction-guide', {}),
		onSuccess: data => {
			if (data.success) {
				queryClient.invalidateQueries({
					queryKey: [QueryKeys.CURRENT_USER_ERROR_HANDLING],
				});
			}
		},
	});
};
