import {
	Billing,
	CalculatorIcon,
	CommunityIcon,
	GraduationIcon,
	HomeIcon,
	MarkerIcon,
	PieIcon,
	ProfileIcon,
	SearchIcon,
	TenantFinderIcon,
	TomIcon,
} from '../assets';
import { Claim } from '../types';

export const userMenuItems = [
	{
		id: Claim['My Profile'],
		text: 'My S8 Profile',
		icon: ProfileIcon,
		link: '/my-profile',
		accordion: true,
	},
	{
		id: Claim['FMR Explorer'],
		text: 'Billing History',
		icon: Billing,
		link: '/billing-history',
		accordion: true,
	},
];

export const LinkItems = [
	{
		id: Claim['FMR Explorer'],
		text: 'FMR Explorer',
		icon: MarkerIcon,
		link: '/fmr-explorer',
		accordion: true,
	},
	{
		id: Claim.Courses,
		text: 'Real Estate Courses',
		icon: GraduationIcon,
		link: '/courses',
		accordion: false,
		optionalClassName: 'second-step',
	},
	{
		id: Claim['City Research'],
		text: 'City Research',
		icon: PieIcon,
		link: '/city-research',
		accordion: true,
	},
	{
		id: Claim['Property Lookup'],
		text: 'Property Lookup',
		icon: SearchIcon,
		link: '/property-lookup',
		accordion: true,
	},
	{
		id: Claim['Deal Calculator'],
		text: 'Deal Calculator',
		icon: CalculatorIcon,
		link: '/deal-calculator',
		accordion: true,
	},
	{
		id: Claim['Deal Explorer'],
		text: 'Deal Explorer',
		icon: HomeIcon,
		link: '/deal-explorer',
		accordion: true,
	},
	{
		id: Claim.Messenger,
		text: 'Community',
		icon: CommunityIcon,
		link: '/messenger/community/1',
		accordion: false,
		disabledBadgeText: 'Coming soon',
		optionalClassName: 'third-step',
	},
	{
		id: Claim.Coaching,
		text: '1 on 1 with Tom',
		icon: TomIcon,
		link: '/messenger/direct/1',
		accordion: false,
		disabledBadgeText: 'Coming soon',
		optionalClassName: 'fourth-step',
	},
	{
		id: Claim.TenantFinder,
		text: 'Tenant Finder',
		icon: TenantFinderIcon,
		link: '/tenant-finder',
		accordion: false,
		disabledBadgeText: 'Coming soon',
	},
	// {
	// 	// id: Claim['FMR Explorer'],
	// 	id: 1,
	// 	text: 'CruzCTRL',
	// 	icon: PropertyManagement,
	// 	link: '',
	// 	accordion: false,
	// 	disabledBadgeText: '',
	// },
];
