import React from 'react';
import { Text, Box, HStack, Button } from '@chakra-ui/react';
import { useTour } from '../Tour/tour';
import { useNavigate } from 'react-router-dom';
import { useCompleteIntroductionGuide } from '../../hooks';

interface OnboardingTourItemProps {
	title: string;
	content: string;
	icon: React.ReactNode;
	index: number;
}

export const OnboardingTourItem: React.FC<OnboardingTourItemProps> = ({
	content,
	title,
	icon,
	index,
}) => {
	const navigate = useNavigate();
	const { currentStep, setIsOpen, setCurrentStep, steps } = useTour();
	const completeIntroductionMutation = useCompleteIntroductionGuide();

	const isLastStep = currentStep === 3;

	const handleNext = async () => {
		if (isLastStep) {
			completeIntroductionMutation.mutate();
			setIsOpen(false);
			navigate('/messenger/direct/1');
		} else {
			setCurrentStep(index + 1);
		}
	};

	return (
		<Box
			bg="blue.100"
			p="16px"
			borderRadius="15px"
			borderTopLeftRadius={0}
			w="320px">
			<Box
				position="relative"
				_before={{
					content: '""',
					height: '20px',
					width: '4px',
					backgroundColor: 'blue.50',
					position: 'absolute',
					left: 0,
					top: '2px',
					borderRadius: '1px',
				}}>
				<Text
					color="blue.50"
					fontSize="16px"
					lineHeight="24px"
					ml="10px">
					Introductory guide
				</Text>
			</Box>
			<HStack mt="12px" color="white">
				{icon}
				<Text
					color="mainBg"
					fontSize="16px"
					fontWeight={600}
					lineHeight="20px">
					{title}
				</Text>
			</HStack>
			<Text
				color="dropBlue"
				fontSize="14px"
				lineHeight="24px"
				mt="4px"
				mb="12px">
				{content}
			</Text>
			<HStack gap="16px">
				<Text color="blue.50" fontSize="14px" flexShrink={0}>
					{index + 1} of {steps.length}
				</Text>
				<Button
					onClick={handleNext}
					w="100%"
					variant={isLastStep ? 's8-solid' : 'tab-selected'}
					isLoading={completeIntroductionMutation.isPending}
					height="40px"
					fontWeight="600"
					borderRadius="4px">
					{isLastStep ? 'Get in touch with Tom' : 'Next'}
				</Button>
			</HStack>
		</Box>
	);
};
