import React, { PropsWithChildren } from 'react';
import { StepType, TourProvider } from '../Tour/tour';
import { OnboardingTourItem } from '../OnboardingTourItem/OnboardingTourItem';
import {
	DealFinder,
	GraduationIcon,
	CommunityIcon,
	CoachingIcon,
} from '../../assets';

const TourSteps: StepType[] = [
	{
		selector: '.first-step',
		content: (
			<OnboardingTourItem
				title="Deal Finder"
				content="This tab will be used to source homes, analyze deals, and find cash flowing gold mines."
				icon={<DealFinder />}
				index={0}
			/>
		),
		position: [266, 128],
	},
	{
		selector: '.second-step',
		content: (
			<OnboardingTourItem
				title="Real Estate Courses"
				content="This tab is where you will find all of the coaching content for both the Section 8 Secrets Course and the Wholesale Course."
				icon={<GraduationIcon />}
				index={1}
			/>
		),
		position: [266, 400],
	},
	{
		selector: '.third-step',
		content: (
			<OnboardingTourItem
				title="Community"
				content="This tab is where you’ll go to connect with other likeminded investors to discuss Section 8 deals, ideas, and to consult with each other in general."
				icon={<CommunityIcon />}
				index={2}
			/>
		),
		position: [266, 450],
	},
	{
		selector: '.fourth-step',
		content: (
			<OnboardingTourItem
				title="1 on 1 With Tom"
				content="This is where you’ll work directly with Tom to secure Section 8 Properties. You have a new message from Tom and he is awaiting your response."
				icon={<CoachingIcon />}
				index={3}
			/>
		),
		position: [266, 520],
	},
];

export const OnboardingTourProvider: React.FC<PropsWithChildren> = ({
	children,
}) => {
	return (
		<TourProvider
			steps={TourSteps}
			position="right"
			padding={0}
			onClickMask={() => {}}
			styles={{
				maskArea: base => ({ ...base, rx: 10 }),
				popover: base => ({
					...base,
					padding: 0,
					borderRadius: '17px',
					borderTopLeftRadius: 0,
					boxShadow: '4px 12px 10px 0px #00000052',
				}),
			}}>
			{children}
		</TourProvider>
	);
};
