import { useDisclosure } from '@chakra-ui/react';
import React, { ReactNode, useEffect } from 'react';
import { PandaDocModal } from './components/PandaDoc.modal';
import { OnboardingFormModal } from '../OnboardingFormModal/OnboardingFormModal';
import { useCheckTOSStatusQuery } from './queries';
import { useTour } from '../Tour/tour';
import { Claim } from '../../types';
import { useSidebarOpenStore } from '../../store';

interface IPandaDocContainerProps {
	children: ReactNode;
	isAgreementSigned: boolean;
	isGuest: boolean;
	isIntroductoryGuideCompleted: boolean;
	claimsIds?: number[];
}

export const PandaDocContainer: React.FC<IPandaDocContainerProps> = ({
	isAgreementSigned,
	isGuest,
	isIntroductoryGuideCompleted,
	children,
	claimsIds,
}) => {
	const {
		isOpen: pandaOpen,
		onOpen: onPandaOpen,
		onClose: onPandaClose,
	} = useDisclosure();

	const { setIsOpen } = useTour();
	const { onOpen } = useSidebarOpenStore();

	const { data } = useCheckTOSStatusQuery(isGuest);

	useEffect(() => {
		if (!isAgreementSigned && !isGuest) {
			onPandaOpen();
		}
	}, [isAgreementSigned, isGuest]);

	useEffect(() => {
		if (
			!isIntroductoryGuideCompleted &&
			data?.value?.isCampaignFieldsSet &&
			data?.value?.isAgreementSigned &&
			claimsIds?.includes(Claim.Messenger)
		) {
			onOpen();
			setTimeout(() => {
				setIsOpen(true);
			}, 400);
		}
	}, [
		isIntroductoryGuideCompleted,
		data?.value?.isAgreementSigned,
		data?.value?.isCampaignFieldsSet,
		claimsIds,
	]);

	return (
		<>
			{children}
			{pandaOpen ? (
				<PandaDocModal
					isOpen={pandaOpen}
					onClose={onPandaClose}
					isAgreementSigned={data?.value?.isAgreementSigned}
				/>
			) : null}
			<OnboardingFormModal
				isOpen={
					!!(
						data?.value?.isAgreementSigned &&
						!data?.value?.isCampaignFieldsSet
					)
				}
			/>
		</>
	);
};
