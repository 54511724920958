import { create } from 'zustand';

interface IState {
	indexes: number | number[];
	onChange: (value: number | number[]) => void;
}

export const useSidebarAccordionStore = create<IState>(set => ({
	indexes: [0],
	onChange: (indexes: number | number[]) => set(() => ({ indexes })),
}));
