import React, { ReactNode } from 'react';
import {
	Accordion,
	AccordionButton,
	AccordionButtonProps,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Flex,
	Text,
	TextProps,
} from '@chakra-ui/react';
import { useSidebarAccordionStore } from '../../store';

interface NavAccordionProps {
	children?: ReactNode;
	menuExpanded: boolean;
	icon: ReactNode;
	title: string;
	buttonProps?: AccordionButtonProps;
	titleProps?: TextProps;
}

export const NavAccordion: React.FC<NavAccordionProps> = ({
	children,
	menuExpanded,
	icon,
	title,
	buttonProps,
	titleProps,
}) => {
	const { indexes, onChange } = useSidebarAccordionStore();
	const showIconOnly = menuExpanded ? 'initial' : 'none';
	return (
		<Accordion
			allowMultiple={true}
			index={indexes}
			onChange={onChange}
			width={menuExpanded ? '100%' : 'auto'}>
			<AccordionItem
				border={'none'}
				borderRadius={'10px'}
				className="first-step">
				{({ isExpanded }) => (
					<>
						<h2>
							<AccordionButton
								borderRadius={`10px 10px ${
									isExpanded ? '0 0' : '10px 10px'
								}`}
								p={'14px'}
								_hover={{ bg: 'blue.200' }}
								bg={
									menuExpanded
										? isExpanded
											? 'blue.200'
											: 'blue.100'
										: isExpanded
										? 'blue.200'
										: 'blue.100'
								}
								justifyContent={'center'}
								gap={'16px'}
								alignItems={'center'}
								{...buttonProps}>
								{icon}
								<Flex
									display={showIconOnly}
									flex="1"
									textAlign="start"
									alignItems={'center'}
									transition={'.4s'}>
									<Text
										fontSize={'16px'}
										fontWeight={'700'}
										whiteSpace="nowrap"
										color="white"
										{...titleProps}>
										{title}
									</Text>
								</Flex>
								<AccordionIcon
									display={showIconOnly}
									bg={'inherit'}
									color={'white'}
									boxSize={7}
								/>
							</AccordionButton>
						</h2>
						<AccordionPanel
							maxH={'284px'}
							borderRadius={'0 0 10px 10px'}
							bg={menuExpanded ? 'blue.200' : 'blue.200'}
							px={menuExpanded ? '10px' : 0}
							pb={2}>
							<Flex gap={'3px'} flexDirection={'column'}>
								{children}
							</Flex>
						</AccordionPanel>
					</>
				)}
			</AccordionItem>
		</Accordion>
	);
};
