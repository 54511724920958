import React from 'react';
import { defaultComponents } from './index';
import { PopoverContentProps } from '../types';

const PopoverContent: React.FC<PopoverContentProps> = ({
	components = {},
	steps,
	setCurrentStep,
	currentStep,
	transition,
	isHighlightingObserved,
	setIsOpen,
}) => {
	const step = steps[currentStep];
	const { Content } = defaultComponents(components);

	return (
		<React.Fragment>
			<Content
				content={step?.content}
				setCurrentStep={setCurrentStep}
				currentStep={currentStep}
				transition={transition}
				isHighlightingObserved={isHighlightingObserved}
				setIsOpen={setIsOpen}
			/>
		</React.Fragment>
	);
};

export default PopoverContent;
